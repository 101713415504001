var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "grid",
      on: {
        mousemove: _vm.headerResizeMove,
        mouseup: function ($event) {
          return _vm.onMouseUpSvg()
        },
      },
    },
    [
      _c(
        "svg",
        {
          attrs: {
            width: _vm.positionLeft(_vm.headerObj.length + 1) + 1,
            height: "24",
          },
        },
        _vm._l(_vm.headerObj, function (col, ci) {
          return _c(
            "g",
            {
              key: ci,
              attrs: { transform: _vm.translateCol(ci) },
              on: {
                mousedown: function ($event) {
                  return _vm.startColumnSelect(ci)
                },
                mousemove: function ($event) {
                  return _vm.changeColumnSelect(ci)
                },
                mouseup: _vm.endColumnSelect,
              },
            },
            [
              _c("rect", {
                staticClass: "col-header",
                attrs: {
                  height: _vm.rowHeight,
                  width: _vm.widthAt(ci),
                  x: "0",
                  y: "0",
                },
              }),
              _c(
                "text",
                {
                  staticClass: "col-header__text",
                  attrs: {
                    height: _vm.rowHeight,
                    width: _vm.widthAt(ci),
                    x: _vm.widthAt(ci) / 2,
                    "text-anchor": "middle",
                    y: "12",
                  },
                },
                [_vm._v(_vm._s(col.name) + " ")]
              ),
              _c("rect", {
                staticClass: "col-header__resize",
                class: { active: ci === _vm.headerResizeAt },
                attrs: {
                  height: _vm.rowHeight,
                  width: 5,
                  x: _vm.widthAt(ci) - 5,
                  y: 0,
                },
                on: {
                  mousedown: function ($event) {
                    $event.stopPropagation()
                    return _vm.headerResizeStart(ci)
                  },
                },
              }),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        {
          ref: "wrapper",
          staticStyle: {
            height: "400px",
            overflow: "scroll",
            position: "relative",
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                height: _vm.data.length * 24,
                width: _vm.positionLeft(_vm.data.length + 1) + 1,
              },
            },
            [
              _vm._l(_vm.data, function (row, ri) {
                return _c(
                  "g",
                  { key: ri, attrs: { transform: _vm.translateRow(ri) } },
                  _vm._l(_vm.data[ri], function (col, ci) {
                    return col.length != 0
                      ? _c(
                          "g",
                          {
                            key: ci,
                            attrs: {
                              fill: _vm.getColor(ci, ri),
                              transform: _vm.translateCol(ci),
                            },
                            on: {
                              mousedown: function ($event) {
                                return _vm.onMouseDownCell(ci, ri)
                              },
                              mousemove: function ($event) {
                                return _vm.onMouseMoveCell(ci, ri)
                              },
                            },
                          },
                          [
                            _c("rect", {
                              attrs: {
                                height: _vm.rowHeight,
                                width: _vm.widthAt(ci),
                                x: "0",
                                y: "0",
                              },
                            }),
                            _c(
                              "text",
                              {
                                attrs: {
                                  height: _vm.rowHeight,
                                  width: _vm.widthAt(ci),
                                  x: "2",
                                  y: "12",
                                },
                              },
                              [_vm._v(_vm._s(col))]
                            ),
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                )
              }),
              _c("rect", {
                staticClass: "selection",
                attrs: {
                  height: _vm.selectionCount.h * _vm.rowHeight,
                  transform: _vm.selectionTransform,
                  width: _vm.selectionSize.w,
                  x: "0",
                  y: "0",
                },
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "editor__frame", style: _vm.editorStyleObj },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editingText,
                    expression: "editingText",
                  },
                ],
                ref: "hiddenInput",
                staticClass: "editor__textarea",
                class: { "editor--visible": _vm.editing },
                attrs: { autofocus: "" },
                domProps: { value: _vm.editingText },
                on: {
                  blur: _vm.onBlur,
                  mousedown: function ($event) {
                    return _vm.onMouseDownCell(_vm.selection.c, _vm.selection.r)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.editingText = $event.target.value
                  },
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }